@charset "utf-8";
i{
	//line-height:0!important;
	vertical-align:top;
	//padding:0 3px;
  &:before{
    vertical-align: top;
  }
}
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
.animation{
	overflow: hidden;
	@include transition(.8s);
	@include transform(rotateY(0deg));
	&.action{
		@include transform(rotateY(360deg));
	}
}

.zoomIn{
	overflow: hidden;
	//@include transition(.4s,$ease-in-out-circ);
	@include transition(.4s);
	@include transform(scale(0, 0));
	&.action{
		@include transform(scale(1, 1));
	}
}

.fade{
	@include transition(.8s);
	@include opacity(0);
	position:relative;
	top:20px;
	&.action{
		top:0;
		@include opacity(1);
	}
}

.fade_01{
	@include transition(opacity,.8s);
	@include opacity(0);
	@include  transition-delay(.3s);
	&.active{
		@include opacity(1);
	}
}

.fade_02{
	@include transition(opacity,.8s);
	@include opacity(0);
	&.active{
		@include opacity(1);
	}
}

.fade_03{
	@include transition(opacity,.8s);
	@include opacity(0);
	@include  transition-delay(.3s);
	.loaded &{
		@include opacity(1);
	}
}

.lazyload {
	opacity: 0;
	//transform: scale(0.8);
	@include transition(.3s)
}

.lazyloaded {
	opacity: 1;
	//@include transition(.3s)
	//transform: scale(1);
	//transition: all 700ms;
}

.lazyloaded + .loadImg{
	display:none!important;
}
/*.lazyload, .lazyloading {
 opacity: 0;
}
.lazyloaded{
  opacity: 1;
  transition: all 0.5s;
}*/
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
.movEmbed{
  position:relative;
  width:100%;
  padding-top:56.25%;
  //@include opacity(0);
  //pointer-events: none;
  //z-index: 20;
  iframe{
    position:absolute;
    top:0;
    left:0;
    width:100% !important;
    height:100% !important;
  }
  &.playnow{
    display: block;
    @include opacity(1);
    pointer-events:unset;
  }
}
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
.way{
	@include opacity(0);
	@include transform(translateY(30px));
	@include transition(all 1s cubic-bezier(0.645, 0.045, 0.355, 1));
	&.active,.showFlag &{
		@include opacity(1);
		@include transform(translateY(0px));
	}
}
.filterWay{
	@include transition(1s);
	@include transition-delay(1s);
	@include opacity(.5);
	@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
		@include filter(grayscale(50%));
		//@include filter(blur(10px) grayscale(50%));
		//@include filter(grayscale(50%));
	}
	&.active{
		@include opacity(1);
    @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
			@include filter(grayscale(0%));
			//@include filter(blur(0px) grayscale(0%));
			//@include filter(grayscale(0%));
    }
  }
}
.fastWay{
	@include opacity(0);
	@include transform(translateY(30px));
	@include transition(all 1s cubic-bezier(0.645, 0.045, 0.355, 1));
	&.active,.showContFlag &{
		@include opacity(1);
		@include transform(translateY(0px));
	}
}
.alphaWay{
	@include opacity(0);
	@include transition(all 1s cubic-bezier(0.645, 0.045, 0.355, 1) .5s);
	&.active{
		@include opacity(1);
	}
}
.scaleway{
	@include opacity(0);
	@include transition(all 0.4s $ease-in-out-circ);
	@include transform(scale(0));
	@include transform-origin(50%,50%);
	//@include transform(rotate(0deg));
	&.active{
		@include opacity(1);
		@include transform(scale(1));
		//@include transform(rotate(360deg));
	}
}
.nullWay{
}
.setAnimation{
	@include opacity(0);
	&.active{
		@include opacity(1);
	}
}
@media screen and (min-width: 900px) {
.typ span {
  opacity: 0;
}
}

////////////////////////////////////////////////
.flash {
  animation: flash .03s infinite;
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: .8;
  }
}
.skew {
  animation: skew 3s infinite;
}
.mainskew {
  animation: mainskew 8s infinite;
}
.nextskew {
  animation: mainskew 16s infinite;
}
@keyframes mainskew{
  0% {
    transform: none;
  }
  33% {
    transform: none;
  }
  33.3% {
    transform: none;
  }
  33.6% {
    transform: none;
  }
  33.9% {
    transform: none;
  }
  66% {
    transform: none;
  }
  66.3% {
    transform: none;
  }
  66.6% {
    transform: none;
  }
  66.9% {
    transform: none;
  }
  77% {
    transform: none;
  }
  77.3% {
    transform: skewX(30deg);
  }
  77.6% {
    transform: skewX(-30deg);
  }
  77.9% {
    transform: none;
  }
  88% {
    transform: none;
  }
  88.3% {
    transform: skewX(5deg);
  }
  88.6% {
    transform: skewX(-5deg);
  }
  88.9% {
    transform: none;
  }
  99% {
    transform: none;
  }
  99.3% {
    transform: skewX(15deg);
  }
  99.6% {
    transform: skewX(-15deg);
  }
  99.9% {
    transform: none;
  }
}
@keyframes skew  {
  0% {
    transform: none;
  }
  33% {
    transform: none;
  }
  33.3% {
    transform: skewX(30deg);
  }
  33.6% {
    transform: skewX(-30deg);
  }
  33.9% {
    transform: none;
  }
  66% {
    transform: none;
  }
  66.3% {
    transform: skewX(5deg);
  }
  66.6% {
    transform: skewX(-5deg);
  }
  66.9% {
    transform: none;
  }
  77% {
    transform: none;
  }
  77.3% {
    transform: skewX(15deg);
  }
  77.6% {
    transform: skewX(-15deg);
  }
  77.9% {
    transform: none;
  }
}
.glitch {
  animation: glitch .3s both infinite;
}
@keyframes glitch{
  0% {
    transform: translate(0);
  }
  12.5% {
    transform: translate(-1px, 1px);
  }
  37.5% {
    transform: translate(-1px, -1px);
  }
  62.5% {
    transform: translate(1px, 1px);
  }
  87.5% {
    transform: translate(1px, -1px);
  }
  100% {
    transform: translate(0);
  }
}
////////////////////////////////////////////////
////////////////////////////////////////////////
@keyframes (linemove_01){
  0% {
    @include transform(translateX(0));
  }
  50% {
    @include transform(translateX(100%));
  }
  50.01% {
    @include transform(translateX(-100%));
  }
  to {
    @include transform(translateX(0));
  }
}
////////////////////////////////////////////////
////////////////////////////////////////////////
.hiddenParts{
  display: none;
}
.coverParts{
  width: 0;
  height: 0;
  @include opacity(0);
}
////////////////////////////////////////////////
////////////////////////////////////////////////
.accordionCont{
  display: none;
}
.accordionLink{
	@include transition(opacity .3s);
	&.active{
		@include opacity(0);
		cursor: default;
		pointer-events: none;
		overflow: hidden;
		height: 0;
		width: 0;
	}
}
@media screen and (min-width: $break-point-middle) {
.accordionLink{
	@include transition(opacity .3s);
	&.active{
		@include opacity(0);
		cursor: default;
		pointer-events: none;
		overflow: hidden;
		height: auto;
		width: auto;
	}
}
}
////////////////////////////////////////////////
////////////////////////////////////////////////
address{
  font-style: normal;
}
////////////////////////////////////////////////
////////////////////////////////////////////////
sup{
	font-size: 50%;
	position: relative;
	top: -.5em;
}
////////////////////////////////////////////////
////////////////////////////////////////////////
.pal{
	.menuOpen &{
		@include opacity(0);
	}
}
.pages .killerPal .pal{
	@include transform(none!important);
}
@media screen and (max-width: 1100px) {
.pal{
	@include transform(none!important);
}
}
@media screen and (min-width: $break-point-middle) {
.pal{
	.menuOpen &{
		@include opacity(0);
	}
}
.pages .killerPal .pal{
	padding-top: 0!important;
}
}
@media screen and (min-width: 1500px) {
.secStyle_01{
  a>.inner,>.inner{
    >.content.pal {
      padding-top: 300/1500*100vw;
      .ttlStyle_08{
        top: 150/1500*100vw;
      }
    }
  }
}
.col-content.pal{
	top: 50/1500*100vw;
}
}
////////////////////////////////////////////////
////////////////////////////////////////////////
$efspeed:.05;
.ttlEffect_01{
	fill:$white;
	stroke:$white;
	stroke-dasharray: 2000;
	stroke-dashoffset: 0;
	stroke-width: 1;
	&.active {
		animation:ttlEffect 3s ease-in 0s;
	}
}
@-webkit-keyframes ttlEffect {
  0% {
	stroke-dashoffset: 2000;
	fill:transparent;
  }
  20% {
	stroke-dashoffset: 2000;
	fill:transparent;
  }
  50% {
	fill:transparent;
  }
  100% {
	stroke-dashoffset: 0;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ttlEffect_02{
	svg{
		overflow: visible;
	}
	path{
    position: relative;
    @include transform-origin(50%,50%);
    @include transition(.9s ease);
    @include opacity(0);
    @include transform(translateY(-10px));
    //@include transform(rotateY(0deg) scale(1.1));
    @for $i from 1 through 40 {
      &:nth-child(#{$i}){
        @include transform-origin(0%,$i+em);
        @include transition-delay(0s + $efspeed*$i);
      }
    }
  }
	&.active path{
		@include opacity(1);
		@include transform(translateY(0px));
	}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#loading{
  background-color: $key-bg;
  position: relative;
  z-index: 1000;
  height: 90vh;
  @include transition(opacity .3s);
  >.inner{
    @include setTbl;
    height: 100%;
  }
  &.loaded{
    @include opacity(0);
    height: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
#loading{
  @include transition(none);
  height: 100vh;
  &.loaded{
    display: none;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.loader {
	display: none;
  color: #ffffff;
  font-size: 8px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@media screen and (min-width: $break-point-middle) {
.loader {
  font-size: 10px;
}
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
