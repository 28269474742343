@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.setMrg_01{
  padding-top: 80/375*100vw;
  padding-bottom: 120/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_01{
  padding-top: 30px;
  padding-bottom: 80px;
  .product-cream &{
    padding-bottom: 140px;
  }
}
}
//////////////////////////////
.setMrg_02{
  margin-bottom: 60/375*100vw;
  margin-top: 120/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_02{
  margin-bottom: 120px;
  margin-top: 200px;
}
}
//////////////////////////////
.setMrg_03{
  margin-bottom: 140/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_03{
  margin-bottom: 210px;
}
}
//////////////////////////////
.setMrg_04{
  margin: 140/375*100vw 0 10/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_04{
  margin: 180px 0 10px;
}
}
//////////////////////////////
@media screen and (min-width: $break-point-middle) {
.setMrg_05{
  margin-bottom: 10px;
}
}
//////////////////////////////
.setMrg_06{
  padding-top: 80/375*100vw;
  padding-bottom: 120/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_06{
  padding-top: 0px;
  padding-bottom: 180px;
}
}
//////////////////////////////
.setMrg_07{
  margin-bottom: 30/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_07{
  margin-bottom: 20px;
}
}
//////////////////////////////
.setMrg_08{
  margin-bottom: 15/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_08{
  margin-bottom: 8px;
}
}
//////////////////////////////
.setMrg_09{
  margin-top: 110/375*100vw;
  margin-bottom: 40/375*100vw;
  .product-cream &{
    margin-top: 0;
    margin-bottom: 40/375*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.setMrg_09{
  margin-top: 40px;
  margin-bottom: 40px;
  .product-cream &{
    margin-top: 0;
    margin-bottom: 40px;
  }
}
}
//////////////////////////////
.setMrg_10{
}
@media screen and (min-width: $break-point-middle) {
.setMrg_10{
  margin-top: 10px;
}
}
//////////////////////////////
.setMrg_11{
  padding-top: 20/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_11{
  padding-top: 20px;
}
}
//////////////////////////////
.setMrg_12{
  margin-bottom: 60/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_12{
  margin-bottom: 100px;
}
}
//////////////////////////////
.setMrg_13{
  padding-top: 80/375*100vw;
  padding-bottom: 70/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_13{
  padding-top: 0px;
  padding-bottom: 130px;
}
}
//////////////////////////////
.setMrg_14{
  padding-bottom: 180/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_14{
  padding-bottom: 60px;
}
}
//////////////////////////////
.setMrg_15{
  padding-top: $header-size;
}
@media screen and (min-width: $break-point-middle) {
.setMrg_15{
  padding-top: 0;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
