@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
body{
  &.breakH{
    height: auto!important;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#outerMenu{
	@include opacity(1);
	position: relative;
	.menuOpen &{
		overflow: hidden;
		position: fixed;
		width: 100%;
	}
}
////////////////////////
.setSp.setLuxy #outerMenu,.breakLuxy #outerMenu{
	position: relative!important;
	transform: none!important;
}
.setSp.setLuxy.menuOpen #outerMenu{
	position: fixed!important;
}
.menuOpen #outerMenu,.breakLuxy #outerMenu{
	transform: none!important;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#clickBlocker{
	width: 100%;
	height: 100%;
	position:fixed;
	top: 0;
	left: 0;
	z-index: 600;
	display: none;
	.menuOpen &{
		display: block;
	}
}
@media screen and (min-width: $break-point-middle) {
#clickBlocker{
	background-color:#1e3445;
	mix-blend-mode: multiply;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#menuPanel{
	background-color: rgba(127, 159, 199, .8);
	color: $white;
	width: 100vw;
	height: 100vh;
	z-index: 2000;
	position: fixed;
	left: -150vw;
	@include transition(.3s);
	.menuOpen &{
		position:relative;
		left: 0;
	}
}
@media screen and (min-width: $break-point-middle) {
#menuPanel{
	width: 426px;
	>.inner{
		overflow: auto;
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#menuPanel .mainNav{
	@include setTbl;
	height: 100vh;
	position: relative;
	top: -20/375*100vw;
	a{
		overflow: hidden;
		display: inline-block;
		@include over_01($white);
		svg{
			fill:$white;
			display: inline-block;
			width: 91/375*100vw;
			height: 18/375*100vw;
		}
	}
	a.current{
		cursor: default;
		pointer-events: none;
		&::before{
			width: 100%;
		}
	}
	li{
		text-align: center;
		margin-bottom: 30/375*100vw;
		&:last-child{
			margin-bottom: 0;
		}
	}
	li.style_00 a svg{
		width: 49/375*100vw;
	}
	li.style_01 a svg{
		width: 58/375*100vw;
	}
	li.style_02 a svg{
		width: 181/375*100vw;
	}
	li.style_03 a svg{
		width: 52/375*100vw;
	}
}
@media screen and (min-width: $break-point-middle) {
#menuPanel .mainNav{
	padding: 20px 62px;
	top: 0px;
	a{
		svg{
			width: 76px;
			height: 14px;
		}
	}
	a:hover{
		svg{
			fill:$white;
		}
	}
	li{
		text-align: left;
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	li.style_00 a svg{
		width: 43px;
	}
	li.style_01 a svg{
		width: 52px;
	}
	li.style_02 a svg{
		width: 152px;
	}
	li.style_03 a svg{
		width: 45px;
	}
	li.style_04 a svg{
		width: 67px;
	}
}
}
@media screen and (min-height: 350px) and (min-width: $break-point-middle){
#menuPanel .mainNav{
	top: 0;
}
}
@media screen and (min-height: 370px) and (min-width: $break-point-middle){
#menuPanel .mainNav{
	//top: -40px;
}
}
@media screen and (min-height: 440px) and (min-width: $break-point-middle){
#menuPanel .mainNav{
	//top: -80px;
}
}
@media screen and (min-height: 520px) and (min-width: $break-point-middle) {
#menuPanel .mainNav{
	li{
		margin-bottom: 16px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.panelFooter{
	padding-top: 0;
}
}
@media screen and (min-height: 736px) and (min-width: $break-point-middle) {
#menuPanel .mainNav{
	top: 0;
	li{
		margin-bottom: 40px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#menuPanel .subNav{
	margin-bottom: 34/375*100vw;
	font-weight: bold;
	font-family: $fontEn;
	@include fz_vw(12);
	line-height: 1em;
	letter-spacing: .08em;
	text-align: center;
	li{
		margin-bottom: 16/375*100vw;
		&:last-child{
			margin-bottom: 0px;
		}
	}
	a{
		color: $white;
		overflow: hidden;
		display: inline-block;
		@include over_01($white);
	}
}
@media screen and (min-width: $break-point-middle) {
#menuPanel .subNav{
	display: none;
	margin-bottom: 30px;
	font-size: 1.2rem;
	text-align: left;
	li{
		margin-bottom: 12px;
		&:last-child{
			margin-bottom: 0px;
		}
	}
	a:hover{
		color: $white;
	}
}
}
@media screen and (min-height: 440px) and (min-width: $break-point-middle) {
#menuPanel .subNav{
	display: block;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.panelFooter{
	// position: absolute;
	// width: 100%;
	// left: 0px;
	// bottom: 30/375*100vw;
	padding-top: 60/375*100vw;
	.snsSec{
		margin-bottom: 20/375*100vw;
		text-align: center;
		a{
			color: $white;
			@include fz_vw(24);
			line-height: 1em;
			display: inline-block;
		}
	}
	.copyRights{
		text-align: center;
		font-family: $fontEn;
		line-height: 1em;
		@include fz_vw(10);
		letter-spacing: .09em;
	}
}
@media screen and (min-height: 800px) {
.panelFooter{
	//bottom: 136/375*100vw;
}
}
@media screen and (min-width: $break-point-middle) {
.panelFooter{
	// left: 62px;
	// bottom: 78px;
	padding-top: 0;
	.snsSec{
		display: none;
		margin-bottom: 20px;
		text-align: left;
		a{
			font-size: 20px;
		}
		a:hover{
			@include opacity(.8);
		}
	}
	.copyRights{
		display: none;
		text-align: left;
		font-size: .8rem;
	}
}
}
@media screen and (min-height: 350px) and (min-width: $break-point-middle){
.panelFooter{
	padding-top: 20px;
	.copyRights{
		display: block;
	}
}
}
@media screen and (min-height: 370px) and (min-width: $break-point-middle){
.panelFooter{
	.snsSec{
		display: block;
	}
}
}
@media screen and (min-height: 650px) and (min-width: $break-point-middle){
.panelFooter{
	padding-top: 60px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
