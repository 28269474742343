@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3000;
  .headSec{
    padding: 10/375*100vw 20/375*100vw;
    background-color: $key-color;
    @include transition(background-color .3s);
  }
  .headSec>.inner{
    position: relative;
  }
  .logo{
    text-align: center;
    position: relative;
    top: 3/375*100vw;
    svg{
      fill:$white;
      width: 57/375*100vw;
      height: 28/375*100vw;
      display: block;
    }
    a{
      display: inline-block;
      &.current{
        cursor: default;
        pointer-events: none;
      }
    }
  }
  .home &,.home.fixedHeader.menuOpen &,.menuOpen &{
    .headSec{
      background-color: transparent;
    }
    .logo{
      @include opacity(0);
    }
  }
  .home.fixedHeader &{
    .headSec{
      background-color: $key-color;
    }
    .logo{
      @include opacity(1);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
header{
  .headSec{
    padding: 18px 50px;
  }
  .logo{
    top: 0;
    svg{
      width: 64px;
      height: 32px;
    }
    a:hover{
      @include opacity(.8);
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#cartMenu{
  position:absolute;
  right: 0;
  top: 50%;
  a{
    display: block;
    width: 18/375*100vw;
    height: 18/375*100vw;
    i{
      color: $white;
      @include fz_vw(18);
      line-height: 1em;
      position: relative;
      top: -10/375*100vw;
    }
    span{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      @include transition(.3s);
    }
    .over{
      display: none;
      @include opacity(0);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
#cartMenu{
  a{
    width: 18px;
    height: 18px;
    i{
      font-size: 1.8rem;
      top: -12px;
    }
    .over{
      display: block;
    }
  }
  a:hover{
    .over{
      @include opacity(1);
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#headTopics{
  background-color: $key-color;
  color: $white;
  padding: 16/375*100vw 20/375*100vw;
  line-height: (18/12)+em;
  @include fz_vw(12);
  .menuOpen &{
    @include opacity(0);
    cursor: default;
    pointer-events: none;
  }
  .fixedHeader &,.newsFlag.menuOpen &{
    display: none;
  }
  .pages &{
    display: none;
    border-bottom: 1px solid $key-color3;
  }
  a{
    overflow: hidden;
    display: inline-block;
    color: $white;
    @include over_02($white);
    position: relative;
    top: 5px;
  }
}
@media screen and (min-width: $break-point-middle) {
#headTopics{
  background-color: $key-color;
  color: $white;
  padding: 6px 0 8px;
  text-align: center;
  border-bottom: 1px solid $key-color;
  line-height: 1.6em;
  font-size: 1.2rem;
  .fixedHeader &{
    border-bottom: 1px solid $key-color2;
  }
  a{
    overflow: hidden;
    display: inline-block;
    color: $white;
    @include over_02($white);
    position: relative;
    top: 5px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#burgerMenu{
  width: 19/$design-num-sp*100vw;
  height: 10/$design-num-sp*100vw;
  margin: 0 auto;
  position:absolute;
  left: 0;
  top: 13/375*100vw;
  z-index: 3000;
  >.inner{
    @include transition(.3s);
    height: 100%;
    cursor: pointer;
  }
  .burger{
    height: 100%;
    >*{
      height: 100%;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
#burgerMenu{
  width: 32px;
  height: 14px;
  top: 8px;
  >.inner{
    &:hover{
      @include scale(1.2,1.0);
      .menu-trigger span{
        //background-color: $key-gold;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.menu-trigger {
  position: relative;
  width: 100%;
  height: 100%;
  display:block;
}
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color:$white;
}
.menu-trigger span:nth-of-type(1) {
  top: 0;
}
.menu-trigger span:nth-of-type(2) {
  bottom: 0;
}

.active .menu-trigger span:nth-of-type(1) {
  @include transform(translateY(4.5/375*100vw) rotate(-136deg));
}
.active .menu-trigger span:nth-of-type(2) {
  @include transform(translateY(-4.5/375*100vw) rotate(136deg));
}
@media screen and (min-width: $break-point-middle) {
.active .menu-trigger span:nth-of-type(1) {
  @include transform(translateY(6.5px) rotate(-136deg));
}
.active .menu-trigger span:nth-of-type(2) {
  @include transform(translateY(-6.5px) rotate(136deg));
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
