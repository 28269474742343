@charset "utf-8";
* {
 @include box-sizing(border-box);
}

*:before, *:after {
 @include box-sizing(border-box);
}

html {
	//height: 100%;
  font-size: 62.5%;
}

body {
	color: $text-color;
	font-family: $base-font-family;
	line-height: $base-line-height;
	background-color: $bg-color;
	-webkit-font-smoothing: antialiased;
	-webkit-text-rendering: optimizelegibility;
	font-size: 1.2rem;
	font-weight: 500;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  background-color: $key-color3;
}

@font-face {
font-family: 'Renner* Book';
font-style: normal;
font-weight: normal;
src: local('Renner* Book'), url('../fonts/Renner_ 400 Book.woff') format('woff');
}

input, button, select, textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

::selection, ::-moz-selection {
 color: #fff;
 background-color: #eee;
}

iframe {
	border: none;
}

a{
    text-decoration: none;
}
.pc {
	display: none!important;
}

.sp {
	display: block!important;
}

.pcInline{
	display: none!important;
}

.spInline {
	display:inline!important;
}

.pcInlineB{
	display: none!important;
}

.spInlineB{
	display:inline-block!important;
}

.pcTd{
	display: none!important;
}

.spTd {
	display:table-cell!important;
}

.pcTbl{
	display: none!important;
}

.spTbl{
	display:table!important;
}

a:hover,a:active{
	//text-decoration:none!important;
	//color:inherit!important;
	img{
		@include opacity(1);
	}
}

//br.sp,br.spInline,br.spTd{
//	&:after {
//		margin-right: -.333em;
//		content:"";
//	}
//}

img{
	display:block;
	max-width:100%;
}

.clearfix {
	 *zoom: 1;
	 &:before,  &:after {
		 content: " ";
		 display: table;
	}
	 &:after {
		 clear: both;
	}
}

.unqMrg{
	margin-top:-60px;
    padding-top:60px;
}

html > body a.link_area {
	display: block;
	position: absolute;
	left: 0!important;
	top: 0!important;
	height: 100%;
	width: 100%;
	text-indent: -9999px; /*IE7、8用ハック*/
	background/*¥**/: #fff\9;/*IE7,8*/
	filter: alpha(opacity=0);
	-ms-filter: "alpha( opacity=0 )";
 	filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0);
	z-index: 80;
	cursor: pointer;
}
