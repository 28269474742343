@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.secStyle_01{
  position: relative;
  height: 100vh;
  overflow: hidden;
  margin-bottom: 10/375*100vw;
  &:last-child{
    margin-bottom: 0;
  }
  >a{
    display: block;
    height: 100%;
  }
  >a>.bg,>.bg{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    @include transition(.8s);
    &.first{
      @include opacity(0);
      .loaded &{
        @include opacity(1);
      }
    }
    >span{
      display: block;
      height: 100%;
      @include background-size(cover);
      background-position: 50% 50%;
    }
    &::after{
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #DBDBE0;
      mix-blend-mode: multiply;
      top: 0;
      left: 0;
      @include transition(.3s);
    }
  }
  a>.inner,>.inner{
    height: 100%;
    position: relative;
    @include setTbl;
    color: $white;
    >.content{
      position: relative;
      z-index: 1500;
      padding-top: $header-size;
      &.style_01{
        vertical-align: bottom;
        padding: $header-size 30/375*100vw 40/375*100vw;
      }
      &.style_02{
        vertical-align: bottom;
        padding-bottom: 40/$design-num-sp*100vw;
      }
    }
    &.bgEffectElem{
      position: fixed;
      top: 0;
      left: 0;
    }
  }
  &.bgEffect{
    //z-index: 1400;
    &.hide{
      @include opacity(0);
    }
    >a>.bg,>.bg{
      position: fixed;
      >span{
        background-position: 50% 0%;
      }
    }
    &.under{
      @include opacity(0);
      @include transition(1.2s);
      &.show{
        @include opacity(1);
      }
      &.hide{
        @include opacity(0);
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_01{
  height: 720/$design-num*100vw;
  margin-bottom: 10px;
  >a{
    height: 720/$design-num*100vw;
    //max-height: 720px;
  }
  a>.inner,>.inner{
    >.content{
      padding-top: 0;
      padding-left: 140/$design-num*100vw;
      padding-right: 0;
      padding-bottom: 0;
      &.style_01{
        vertical-align: middle;
        $gap:50/$design-num*100vw;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: calc(50% + #{$gap});
        padding-left: -webkit-calc(50% + #{$gap});
      }
      &.style_02{
        vertical-align: middle;
        padding-bottom: 0;
      }
    }
    &.bgEffectElem{
      position:static;
    }
  }
  >a:hover>.bg::after,&.isOver>a>.bg::after,&.isOver>.bg::after{
    @include opacity(.5);
  }
  &.bgEffect{
    &.hide{
      @include opacity(1);
    }
    >a>.bg,>.bg{
      position:absolute;
      >span{
        background-position: 50% 50%;
      }
    }
    &.under{
      @include opacity(1);
    }
  }
}
}
///////////////////////////////
.secStyle_02{
  @extend .secStyle_01;
  a>.inner,>.inner{
    >*{
      vertical-align: top;
    }
    >.content{
      padding-top: 76/375*100vw;
    }
  }
  .cont-inner{
    text-align: center;
  }
  .setPos{
    position: absolute;
    bottom: 46/375*100vw;
    width: 100%;
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_02{
  .pages &{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  a>.inner,>.inner{
    >*{
      vertical-align:middle;
    }
    >.content{
      padding-top: 0;
    }
  }
  .cont-inner{
    width: 305px;
  }
  .setPos{
    bottom: 78/$design-num*100vw;
    width: 305px;
  }
}
}
///////////////////////////////
.secStyle_03{
  position: relative;
  >.inner{
    >*{
      margin-bottom: 10/375*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_03{
  >.inner{
    @include setTbl;
    >*{
      margin-bottom: 0;
    }
  }
}
}
///////////////////////////////
.secStyle_04{
  @extend .secStyle_01;
  height: 375/375*100vw;
  >a>.inner{
    >.content{
      padding-left: 0;
      text-align: center;
      padding-top: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_04{
  height: 640/$design-num*100vw;
  >a:hover>.bg::after{
    @include opacity(0);
  }
  &.full{
    height: 360/$design-num*100vw;
    >a{
      height: 360/$design-num*100vw;
    }
  }
}
}
///////////////////////////////
.secStyle_05{
  padding: 80/375*100vw 30/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.secStyle_05{
  padding: 174px 40px;
  >.inner{
    max-width: 747px;
    margin: 0 auto;
  }
}
}
///////////////////////////////
.secStyle_06{
  @extend .secStyle_01;
  height: 90vh;
  margin-bottom: 10/375*100vw;
  >a>.bg,>.bg{
    &::after{
      display: none;
    }
  }
  a>.inner,>.inner{
    >.content{
      padding-bottom: 90/375*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_06{
  height: 720/$design-num*100vw;
  margin-bottom: 10px;
  a>.inner,>.inner{
    >.content{
      padding-left: 0;
      padding-bottom: 0;
    }
  }
}
}
///////////////////////////////
.secStyle_07{
  @extend .secStyle_01;
  a>.inner,>.inner{
    >.content{
      padding: 0 30/375*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_07{
  a>.inner,>.inner{
    >.content{
      padding-left: 140/$design-num*100vw;
    }
  }
  .philosophy &{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
}
///////////////////////////////
.secStyle_08{
  padding: 0 30/375*100vw;
  position: relative;
}
@media screen and (min-width: $break-point-middle) {
.secStyle_08{
  padding: 0 40px;
  >.inner{
    max-width: 980px;
    margin: 0 auto;
  }
}
}
///////////////////////////////
.secStyle_09{
  position: relative;
  margin-bottom: 100/375*100vw;
  padding: 0 30/375*100vw;
  .col-img{
    position: relative;
    margin-right: -30/375*100vw;
    .imgStyle{
      img{
        @include imgset;
      }
    }
  }
  .col-content{
    position: relative;
    margin-top: -14/375*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_09{
  margin-bottom:90px;
  padding: 0 40px;
  >.inner{
    max-width: 980px;
    margin: 0 auto;
  }
  .col-img{
    margin-right: 0;
    .imgStyle{
      max-width: 748px;
      margin: 0 auto;
    }
  }
  .col-content{
    margin-top: -80px;
  }
}
}
///////////////////////////////
.secStyle_10{
  padding: 0 30/375*100vw;
  margin-bottom: 100/375*100vw;
  >.inner>.tbl{
    @include display-flex;
  	@include flex-direction(column-reverse);
  }
  .col-content{
    position: relative;
    z-index: 10;
    margin-top: -14/375*100vw;
  }
  .col-img{
    position: relative;
    margin-left: -30/375*100vw;
    .imgStyle{
      img{
        @include imgset;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_10{
  padding: 0 0 0 40px;
  margin-bottom: 100px;
  >.inner{
    max-width: 980px;
    margin: 0 auto;
    position: relative;
  }
  >.inner>.tbl{
    @include setTbl;
    >*{
      vertical-align: bottom;
    }
  }
  .col-content{
    white-space: nowrap;
    margin-top: 0;
  }
  .col-img{
    height: (477+90)+px;
    margin-left: 0;
    .imgStyle{
      width: 748px;
      position: absolute;
      right:-130px;
      top: 0;
      &.style_01{
        top: -10px;
      }
    }
  }
}
}
///////////////////////////////
.secStyle_11{
  position: relative;
  margin-bottom: 100/375*100vw;
  padding: 0 30/375*100vw;
  .col-content{
    position: relative;
    z-index: 10;
    margin-top: -14/375*100vw;
  }
  .col-img{
    position: relative;
    margin-right: -30/375*100vw;
    .imgStyle{
      img{
        @include imgset;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_11{
  padding: 0 40px 0 0;
  margin-bottom: 200px;
  >.inner{
    max-width: 980px;
    margin: 0 auto;
    position: relative;
  }
  >.inner>.tbl{
    @include setTbl;
  }
  .col-content{
    white-space: nowrap;
    padding-left: 45/980*100%;
    margin-top: 0;
  }
  .col-img{
    width: 618/980*100%;
    height: 477px;
    margin-right: 0;
    .imgStyle{
      width: 748px;
      position: absolute;
      left:-130px;
      top: 0;
    }
  }
}
}
///////////////////////////////
.secStyle_12{
  padding: 0 30/375*100vw;
  margin-bottom: 100/375*100vw;
  >.inner>.tbl{
    @include display-flex;
  	@include flex-direction(column-reverse);
  }
  .col-content{
    position: relative;
    z-index: 10;
    margin-top: -14/375*100vw;
  }
  .col-img{
    position: relative;
    margin-left: -30/375*100vw;
    margin-right: -30/375*100vw;
    .imgStyle{
      img{
        @include imgset;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_12{
  padding: 0 0 0 40px;
  margin-bottom: 100px;
  >.inner{
    max-width: 980px;
    margin: 0 auto;
    position: relative;
  }
  >.inner>.tbl{
    @include setTbl;
    >*{
      vertical-align: bottom;
    }
  }
  .col-content{
    white-space: nowrap;
    padding-bottom: 66px;
    margin-top: 0;
  }
  .col-img{
    height: 748px;
    margin-left: 0;
    margin-right: 0;
    .imgStyle{
      width: 748px;
      position: absolute;
      right:-130px;
      top: 0;
    }
  }
}
}
///////////////////////////////
.secStyle_13{
  position: relative;
  margin-bottom: 100/375*100vw;
  padding: 0 30/375*100vw;
  .col-content{
    position: relative;
    z-index: 10;
    margin-top: -14/375*100vw;
  }
  .col-img{
    position: relative;
    margin-left: -30/375*100vw;
    height: 207/375*100vw;
    .imgStyle{
      width: 166/375*100vw;
      position: absolute;
      img{
        @include imgset;
      }
    }
    .style_01{
      bottom: 0;
      left: 0;
    }
    .style_02{
      top: 0;
      left: 180/375*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_13{
  padding: 0 0 0 40px;
  margin-bottom: 270px;
  >.inner{
    max-width: 980px;
    margin: 0 auto;
    position: relative;
  }
  >.inner>.tbl{
    @include setTbl;
    >*{
      vertical-align: bottom;
    }
  }
  .col-content{
    width: 670/980*100%;
    white-space: nowrap;
    margin-top: 0;
  }
  .col-img{
    height: 488px;
    margin-left: 0;
    .imgStyle{
      width: 300px;
    }
    .style_01{
      bottom: auto;
      left: auto;
      top: 0;
      right: -160px;
    }
    .style_02{
      top: auto;
      left: auto;
      bottom: 0;
      right: 160px;
    }
  }
}
}
///////////////////////////////
.secStyle_14{
  @extend .secStyle_01;
  height: 230/375*100vw;
  .ttlStyle_02{
    margin-bottom: 0;
  }
  a>.inner,>.inner{
    >.content{
      padding: 0;
      text-align: center;
    }
  }
  .cont-inner{
    text-align: center;
  }
  .setPos{
    display: none;
    position: absolute;
    bottom: 40/375*100vw;
    width: 100%;
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_14{
  height: 400/$design-num*100vw;
  >a{
    height: 100%;
    max-height: none;
  }
  .setPos{
    display: block;
    bottom: 56/$design-num*100vw;
  }
}
}
///////////////////////////////
.secStyle_15{
  margin-top: 8/375*100vw;
  >.inner{
    >*{
      margin-bottom: 8/375*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_15{
  margin-top: 0;
  >.inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >*{
      margin-bottom: 0;
      width: 50%;
      overflow: hidden;
      .product-serum &,.product-mist &,.product-cream &{
        width: 1/3*100%;
      }
    }
  }
}
}
///////////////////////////////
.secStyle_16{
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.articleWrap_01{
  >*{
    border-top: 1px solid $key-gray;
    padding: 12/375*100vw 0 18/375*100vw;
    &:last-child{
      border-bottom: 1px solid $key-gray;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.articleWrap_01{
  >*{
    padding: 10px 0 18px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.elemWrap_01{
  text-align: center;
  >*{
    width: 150/375*100vw;
    display: inline-block;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemWrap_01{
  >*{
    width: 160px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
