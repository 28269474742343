@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.articleStyle_01{
  a{
    text-decoration: underline;
    color: $text-color;
    @include transition(none);
  }
  .upDate{
    font-family: $fontEn;
    line-height: 1em;
    @include fz_vw(10);
    margin-bottom: 7/375*100vw;
  }
  .ttl{
    margin-bottom: 7/375*100vw;
    @include killSpace;
    a.accordionLink{
      text-decoration: none;
      @include over_02($hover-color);
      overflow: hidden;
      position: relative;
      top: 5/375*100vw;
    }
  }
  h2,.summary{
    @include fz_vw(12);
    line-height: (20/12)+em;
    letter-spacing: .03em;
  }
  h2{
    font-weight: normal;
    margin-right: 1em;
    &:last-child{
      margin-right: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_01{
  a:hover{
    color: $hover-color;
    text-decoration: none;
  }
  .upDate{
    font-size: 1.0rem;
    margin-bottom: 10px;
  }
  .ttl{
    margin-bottom: 10px;
    a.accordionLink{
      top: 5px;
    }
  }
  h2,.summary{
    font-size: 1.2rem;
    line-height: (26/12)+em;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
