@charset "utf-8";
/* ---------------------------
    util
--------------------------- */

/* logtag */
#logtag { display: none; }

/* clearfix */
.clearfix{zoom:1;}
.clearfix:after{ content : ''; display : block; clear : both; }

/**
   * force float-left
   */
.fl{float:left !important;}

/**
   * force float-right
   */
.fr {float: right !important;}

/**
   * force clear-both
   */
.clear {clear:both !important;}


/* position */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

/* text-align */

/**
* force align-left
*/
.al { text-align: left !important; }

/**
* force align-right
*/
.ar { text-align: right !important; }

/**
* force align-center
*/
.ac { text-align: center !important; }

/* bold */
.bold, strong {
  font-weight: bold;
}

/* caption */
.caption {
  font-size: 11px;
}

/* margin-padding */

/**
* force margin-top XXpx
*/
.mt00{margin-top:0px !important;}
.mt05{margin-top:5px !important;}
.mt10{margin-top:10px !important;}
.mt15{margin-top:15px !important;}
.mt20{margin-top:20px !important;}
.mt25{margin-top:25px !important;}
.mt30{margin-top:30px !important;}
.mt35{margin-top:35px !important;}
.mt40{margin-top:40px !important;}
.mt45{margin-top:45px !important;}
.mt50{margin-top:50px !important;}
.mt55{margin-top:55px !important;}
.mt60{margin-top:60px !important;}
.mt65{margin-top:65px !important;}
.mt70{margin-top:70px !important;}
.mt75{margin-top:75px !important;}
.mt80{margin-top:80px !important;}
.mt85{margin-top:85px !important;}
.mt90{margin-top:90px !important;}
.mt95{margin-top:95px !important;}
.mt100{margin-top:100px !important;}

/**
* force margin-right XXpx
*/
.mr00{margin-right:0px !important;}
.mr05{margin-right:5px !important;}
.mr10{margin-right:10px !important;}
.mr15{margin-right:15px !important;}
.mr20{margin-right:20px !important;}
.mr25{margin-right:25px !important;}
.mr30{margin-right:30px !important;}
.mr35{margin-right:35px !important;}
.mr40{margin-right:40px !important;}
.mr45{margin-right:45px !important;}
.mr50{margin-right:50px !important;}
.mr55{margin-right:55px !important;}
.mr60{margin-right:60px !important;}
.mr65{margin-right:65px !important;}
.mr70{margin-right:70px !important;}
.mr75{margin-right:75px !important;}
.mr80{margin-right:80px !important;}
.mr85{margin-right:85px !important;}
.mr90{margin-right:90px !important;}
.mr95{margin-right:95px !important;}
.mr100{margin-right:100px !important;}

/**
* force margin-bottom XXpx
*/
.mb00{margin-bottom:0px !important;}
.mb05{margin-bottom:5px !important;}
.mb10{margin-bottom:10px !important;}
.mb15{margin-bottom:15px !important;}
.mb20{margin-bottom:20px !important;}
.mb25{margin-bottom:25px !important;}
.mb30{margin-bottom:30px !important;}
.mb35{margin-bottom:35px !important;}
.mb40{margin-bottom:40px !important;}
.mb45{margin-bottom:45px !important;}
.mb50{margin-bottom:50px !important;}
.mb55{margin-bottom:55px !important;}
.mb60{margin-bottom:60px !important;}
.mb65{margin-bottom:65px !important;}
.mb70{margin-bottom:70px !important;}
.mb75{margin-bottom:75px !important;}
.mb80{margin-bottom:80px !important;}
.mb85{margin-bottom:85px !important;}
.mb90{margin-bottom:90px !important;}
.mb95{margin-bottom:95px !important;}
.mb100{margin-bottom:100px !important;}

/**
* force margin-left XXpx
*/
.ml00{margin-left:0px !important;}
.ml05{margin-left:5px !important;}
.ml10{margin-left:10px !important;}
.ml15{margin-left:15px !important;}
.ml20{margin-left:20px !important;}
.ml25{margin-left:25px !important;}
.ml30{margin-left:30px !important;}
.ml35{margin-left:35px !important;}
.ml40{margin-left:40px !important;}
.ml45{margin-left:45px !important;}
.ml50{margin-left:50px !important;}
.ml55{margin-left:55px !important;}
.ml60{margin-left:60px !important;}
.ml65{margin-left:65px !important;}
.ml70{margin-left:70px !important;}
.ml75{margin-left:75px !important;}
.ml80{margin-left:80px !important;}
.ml85{margin-left:85px !important;}
.ml90{margin-left:90px !important;}
.ml95{margin-left:95px !important;}
.ml100{margin-left:100px !important;}

/**
* force padding-top XXpx
*/
.pt00{padding-top:0px !important;}
.pt05{padding-top:5px !important;}
.pt10{padding-top:10px !important;}
.pt15{padding-top:15px !important;}
.pt20{padding-top:20px !important;}
.pt25{padding-top:25px !important;}
.pt30{padding-top:30px !important;}
.pt35{padding-top:35px !important;}
.pt40{padding-top:40px !important;}
.pt45{padding-top:45px !important;}
.pt50{padding-top:50px !important;}
.pt55{padding-top:55px !important;}
.pt60{padding-top:60px !important;}
.pt65{padding-top:65px !important;}
.pt70{padding-top:70px !important;}
.pt75{padding-top:75px !important;}
.pt80{padding-top:80px !important;}
.pt85{padding-top:85px !important;}
.pt90{padding-top:90px !important;}
.pt95{padding-top:95px !important;}
.pt100{padding-top:100px !important;}

/**
   * force padding-right XXpx
   */
.pr00{padding-right:0px !important;}
.pr05{padding-right:5px !important;}
.pr10{padding-right:10px !important;}
.pr15{padding-right:15px !important;}
.pr20{padding-right:20px !important;}
.pr25{padding-right:25px !important;}
.pr30{padding-right:30px !important;}
.pr35{padding-right:35px !important;}
.pr40{padding-right:40px !important;}
.pr45{padding-right:45px !important;}
.pr50{padding-right:50px !important;}
.pr55{padding-right:55px !important;}
.pr60{padding-right:60px !important;}
.pr65{padding-right:65px !important;}
.pr70{padding-right:70px !important;}
.pr75{padding-right:75px !important;}
.pr80{padding-right:80px !important;}
.pr85{padding-right:85px !important;}
.pr90{padding-right:90px !important;}
.pr95{padding-right:95px !important;}
.pr100{padding-right:100px !important;}

/**
* force padding-bottom XXpx
*/
.pb00{padding-bottom:0px !important;}
.pb05{padding-bottom:5px !important;}
.pb10{padding-bottom:10px !important;}
.pb15{padding-bottom:15px !important;}
.pb20{padding-bottom:20px !important;}
.pb25{padding-bottom:25px !important;}
.pb30{padding-bottom:30px !important;}
.pb35{padding-bottom:35px !important;}
.pb40{padding-bottom:40px !important;}
.pb45{padding-bottom:45px !important;}
.pb50{padding-bottom:50px !important;}
.pb55{padding-bottom:55px !important;}
.pb60{padding-bottom:60px !important;}
.pb65{padding-bottom:65px !important;}
.pb70{padding-bottom:70px !important;}
.pb75{padding-bottom:75px !important;}
.pb80{padding-bottom:80px !important;}
.pb85{padding-bottom:85px !important;}
.pb90{padding-bottom:90px !important;}
.pb95{padding-bottom:95px !important;}
.pb100{padding-bottom:100px !important;}

/**
* force padding-left XXpx
*/
.pl00{padding-left:0px !important;}
.pl05{padding-left:5px !important;}
.pl10{padding-left:10px !important;}
.pl15{padding-left:15px !important;}
.pl20{padding-left:20px !important;}
.pl25{padding-left:25px !important;}
.pl30{padding-left:30px !important;}
.pl35{padding-left:35px !important;}
.pl40{padding-left:40px !important;}
.pl45{padding-left:45px !important;}
.pl50{padding-left:50px !important;}
.pl55{padding-left:55px !important;}
.pl60{padding-left:60px !important;}
.pl65{padding-left:65px !important;}
.pl70{padding-left:70px !important;}
.pl75{padding-left:75px !important;}
.pl80{padding-left:80px !important;}
.pl85{padding-left:85px !important;}
.pl90{padding-left:90px !important;}
.pl95{padding-left:95px !important;}
.pl100{padding-left:100px !important;}

/**align**/
.alignCenter{
	text-align:center;
}

/* img */
img {
    vertical-align: bottom;
}

.bold{
	font-weight:bold;
}