@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ttlStyle_01{
  //text-align: center;
  text-align: left;
  margin-bottom: 20/375*100vw;
  svg{
    fill:$white;
    width: 249/375*100vw;
    height: 61/375*100vw;
    // width: 15/375*100vw;
    // height: 312/375*100vw;
    display: inline-block;
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_01{
  line-height: (44/28)+em;
  font-size: 2.8rem;
  margin-bottom: 32px;
  letter-spacing: .03em;
  font-weight:normal;
  svg{
    width: 311px;
    height: 70px;
  }
}
}
///////////////////////////////////////
.ttlStyle_02{
  margin-bottom: 16/375*100vw;
  svg{
    fill:$white;
    width: 153/375*100vw;
    height: 17/375*100vw;
    display: inline-block;
  }
  &.style_01 svg{
    width: 124/375*100vw;
  }
  &.style_02 svg{
    width: 161/375*100vw;
  }
  &.style_03 svg{
    width: 46/375*100vw;
  }
  &.style_04 svg{
    width: 158/375*100vw;
  }
  &.style_05 svg{
    width: 158/375*100vw;
  }
  .philosophy &{
    //margin-bottom: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_02{
  margin-bottom: 26px;
  svg{
    width: 191px;
    height: 22px;
  }
  &.style_01 svg{
    width: 156px;
  }
  &.style_02 svg{
    width: 201px;
  }
  &.style_03 svg{
    width: 58/$design-num*100vw;
    height: 22/$design-num*100vw;
  }
  &.style_04 svg{
    width: 197px;
    height: 22px;
  }
  &.style_05 svg{
    width: 197px;
    height: 22px;
  }
}
}
///////////////////////////////////////
.ttlStyle_03{
  text-align: center;
  margin-bottom: 35/375*100vw;
  .en{
    margin-bottom: 10/375*100vw;
    svg{
      fill:$text-color;
      width: 60/375*100vw;
      height: 15/375*100vw;
    }
  }
  h2{
    line-height: 1em;
    @include fz_vw(12);
    font-weight: normal;
    letter-spacing: .05em;
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_03{
  margin-bottom: 40px;
  .en{
    margin-bottom: 10px;
    svg{
      width: 91px;
      height: 23px;
    }
  }
  h2{
    font-size: 1.2rem;
  }
}
}
///////////////////////////////////////
.ttlStyle_04{
  text-align: center;
  svg{
    display: inline-block;
    fill:$white;
    width: 154/375*100vw;
    height: 18/375*100vw;
    .product-mist &{
      width: 125/375*100vw;
    }
    .product-cream &{
      width: 158/375*100vw;
    }
    .philosophy &{
      width: 183/375*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_04{
  svg{
    width: 192px;
    height: 23px;
    .product-mist &{
      width: 156px;
    }
    .product-cream &{
      width: 198px;
    }
    .philosophy &{
      width: 228px;
    }
  }
}
}
///////////////////////////////////////
.ttlStyle_05{
  margin-bottom: 30/375*100vw;
  svg{
    display: inline-block;
    fill:$white;
    width: 234/375*100vw;
    height: 53/375*100vw;
    .product-mist &{
      width: 148/375*100vw;
    }
  }
  &.style_01 svg{
    width: 177/375*100vw;
    height: 86/375*100vw;
  }
  &.style_02 svg{
    width: 150/375*100vw;
    height: 51/375*100vw;
  }
  &.style_03 svg{
    width: 259/375*100vw;
    height: 51/375*100vw;
  }
  &.style_04 svg{
    width: 226/375*100vw;
    height: 51/375*100vw;
  }
  &.style_05 svg{
    width: 268/375*100vw;
    height: 49/375*100vw;
  }
  &.style_06 svg{
    width: 277/375*100vw;
    height: 87/375*100vw;
  }
  &.style_07 svg{
    width: 226/375*100vw;
    height: 52/375*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_05{
  margin-bottom: 38px;
  svg{
    width: 357px;
    height: 70px;
    .product-mist &{
      width: 226px;
    }
  }
  &.style_01 svg{
    width: 548px;
    height: 70px;
  }
  &.style_02 svg{
    width: 206px;
    height: 70px;
  }
  &.style_03 svg{
    width: 356px;
    height: 70px;
  }
  &.style_04 svg{
    width: 312px;
    height: 70px;
  }
  &.style_05 svg{
    width: 358px;
    height: 67px;
  }
  &.style_06 svg{
    width: 494px;
    height: 71px;
  }
  &.style_07 svg{
    width: 348px;
    height: 70px;
  }
}
}
///////////////////////////////////////
.ttlStyle_06{
  font-family: $fontEn;
  text-align: center;
  line-height: (26/18)+em;
  @include fz_vw(18);
  margin-bottom: 20/375*100vw;
  font-weight: normal;
  letter-spacing: .14em;
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_06{
  line-height: (40/30)+em;
  font-size: 3.0rem;
  margin-bottom: 40px;
}
}
///////////////////////////////////////
.ttlStyle_07{
  margin-bottom: 18/375*100vw;
  >span{
    display: block;
  }
  .en{
    line-height: 1em;
    @include fz_vw(12);
    font-family: $fontEn;
    letter-spacing: .2em;
    position: absolute;
    top: -230/375*100vw;
    left: 0;
    .secStyle_12 &{
      top: -385/375*100vw;
      line-height: (18/12)+em;
    }
    .secStyle_13 &{
      top: -193/375*100vw;
      line-height: (18/12)+em;
    }
  }
  .ja{
    font-weight: normal;
    line-height: (26/16)+em;
    @include fz_vw(16);
    letter-spacing: .08em;
    .secStyle_11 &{
      padding-top: 40/375*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_07{
  margin-bottom: 28px;
  .en{
    font-size: 1.4rem;
    margin-bottom: 18px;
    position: relative;
    top: 0;
    .secStyle_12 &,.secStyle_13 &{
      top: 0;
      line-height: 1em;
    }
  }
  .ja{
    line-height: (36/24)+em;
    font-size: 2.4rem;
    .secStyle_11 &{
      padding-top: 0;
    }
  }
}
}
///////////////////////////////////////
.ttlStyle_08{
  position:absolute;
  right: 12/375*100vw;
  top: 30/375*100vw;
  height: 100%;
  svg{
    margin: auto;
    fill:$white;
    width: 8/375*100vw;
    height: 108/375*100vw;
  }
  &.style_01 svg{
    height: 160/375*100vw;
  }
  &.style_02 svg{
    height: 86/375*100vw;
  }
  &.style_03 svg{
    height: 170/375*100vw;
  }
  &.style_04 svg{
    height: 91/375*100vw;
  }
  &.style_05 svg{
    height: 141/375*100vw;
  }
  &.style_06 svg{
    height: 94/375*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_08{
  position:absolute;
  right: 100/$design-num*100vw;
  top: 0;
  height: 100%;
  @include display-flex;
  @include align-items(center);
  svg{
    margin: auto;
    fill:$white;
    width: 11px;
    height: 151px;
  }
  &.style_01 svg{
    height: 224px;
  }
  &.style_02 svg{
    height: 120px;
  }
  &.style_03 svg{
    height: 238px;
  }
  &.style_04 svg{
    height: 128px;
  }
  &.style_05 svg{
    height: 197px;
  }
  &.style_06 svg{
    height: 131px;
  }
}
}
///////////////////////////////////////
.ttlStyle_09{
  @include fz_vw(14);
  line-height: 1em;
  margin-bottom: 10/$design-num-sp*100vw;
  letter-spacing: .03em;
  &::after{
    content: '：';
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_09{
  font-size: 1.8rem;
  margin-bottom: 8px;
  &::after{
    display: none;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.summaryStyle_01{
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  line-height: (32/12)+em;
  @include fz_vw(12);
  letter-spacing: .08em;
  margin: auto;
  height: 390/375*100vw;
  >p{
    margin-bottom: 2em;
    &:last-child{
      margin-bottom: 0;
    }
    >span{
      -webkit-text-combine: horizontal;
      -ms-text-combine-horizontal: all;
      text-combine-upright: all;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.summaryStyle_01{
  -ms-writing-mode:lr-tb;
  writing-mode:horizontal-tb;
  line-height: (36/14)+em;
  font-size: 1.4rem;
  height: auto;
}
}
///////////////////////////////////////
.summaryStyle_02{
  svg{
    display: inline-block;
    fill:$white;
    width: 262/375*100vw;
    height: 12/375*100vw;
  }
  &.style_01 svg{
    width: 193/375*100vw;
  }
  &.style_02 svg{
    width: 221/375*100vw;
    height: 36/375*100vw;
  }
  &.style_03 svg{
    width: 150/375*100vw;
    height: 35/375*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.summaryStyle_02{
  svg{
    width: 305px;
    height: 13px;
  }
  &.style_01 svg{
    width: 193px;
  }
  &.style_02 svg{
    width: 258px;
    height: 42px;
  }
  &.style_03 svg{
    width: 272px;
    height: 13px;
  }
}
}
///////////////////////////////////////
.summaryStyle_03{
  line-height: (26/12)+em;
  @include fz_vw(12);
  letter-spacing: .04em;
  text-align: justify;
  p{
    margin-bottom: 1em;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.summaryStyle_03{
  letter-spacing: .06em;
  line-height: (36/14)+em;
  font-size: 1.4rem;
}
}
///////////////////////////////////////
.summaryStyle_04{
  line-height: (22/12)+em;
  @include fz_vw(12);
  letter-spacing: .03em;
  text-align: justify;
  p{
    margin-bottom: 2em;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.summaryStyle_04{
  line-height: (30/14)+em;
  font-size: 1.4rem;
}
}
///////////////////////////////////////
.summaryStyle_05{
  text-align: justify;
  line-height: (20/12)+em;
  @include fz_vw(12);
}
@media screen and (min-width: $break-point-middle) {
.summaryStyle_05{
  line-height: (24/12)+em;
  font-size: 1.1rem;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.btnStyle_01{
  @include btnsetting;
  border: 1px solid $white;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 10/375*100vw 14/375*100vw;
  >span{
    position: relative;
    display: block;
  }
  svg{
    fill:$white;
    width: 36/375*100vw;
    height: 10/375*100vw;
    display:inline-block;
  }
}
@media screen and (min-width: $break-point-middle) {
.btnStyle_01{
  padding: 10px;
  svg{
    width: 42px;
    height: 10px;
  }
  &::before{
    content: '';
    background-color: $key-color;
    @include transition(.3s $ease-out-sine);
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 300px;
  }
  &:hover{
    border: 1px solid $key-color;
    &::before{
      left: 0;
    }
  }
}
}
///////////////////////////////////////
.btnStyle_02{
  @extend .btnStyle_01;
  text-align: left;
  i{
    color: $white;
    position: absolute;
    right: 0px;
    top: 50%;
    line-height: 0em;
    @include transition(.3s);
  }
}
@media screen and (min-width: $break-point-middle) {
.btnStyle_02{
  padding: 10px 16px;
  &:hover{
    i{
      right: -10px;
    }
  }
}
}
///////////////////////////////////////
.btnStyle_03{
  background-color: $key-color2;
  color: $white;
  @include fz_vw(12);
  line-height: 1em;
  padding: 14/375*100vw 20/375*100vw;
  overflow: hidden;
  position: relative;
  letter-spacing: .08em;
  >span{
    position: relative;
    display: block;
  }
  &::before{
    content: '';
    background-color: lighten($key-color2,5%);
    @include transition(.3s $ease-out-sine);
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 300px;
  }
  i{
    @include transition(.3s);
    position: absolute;
    @include fz_vw(10);
    line-height: 0em;
    right: 0;
    top: 50%;
  }
}
@media screen and (min-width: $break-point-middle) {
.btnStyle_03{
  font-size: 1.4rem;
  padding: 12px 20px;
  i{
    font-size: 1.0rem;
  }
  &:hover{
    &::before{
      left: 0;
    }
    i{
      right: -10px;
    }
  }
}
}
///////////////////////////////////////
.btnStyle_04{
  a{
    display: block;
    position: relative;
    overflow: hidden;
    //border: 1px solid $key-color2;
    >.inner{
      @include setTbl;
    }
    .sec-label{
      background-color: $key-color2;
      color: $white;
      position: relative;
      @include fz_vw(14);
      line-height: 1em;
      letter-spacing: .1em;
      position: relative;
      //overflow: hidden;
      width: 160/315*100%;
      .sec-inner{
        text-align: center;
        &.en{
          font-family: $fontEn;
        }
      }
      .sec-inner span{
        margin-top: 5/375*100vw;
        display: block;
        @include fz_vw(10);
        line-height: 1em;
      }
    }
    .sec-price{
      color: $key-color2;
      @include fz_vw(10);
      line-height: 1em;
      position: relative;
      overflow: hidden;
      border: 1px solid $key-color2;
      border-left: none;
      .sec-inner{
        text-align: center;
      }
      .sec-inner span{
        display: block;
        margin-bottom: 5/375*100vw;
        letter-spacing: .1em;
        @include fz_vw(18);
        line-height: 1em;
        font-family: $fontEn;
        font-weight: bold;
      }
    }
    .sec-inner{
      position: relative;
      display: block;
      padding: 9/375*100vw;
    }
    i{
      display: none;
    }
  }
  a>.inner{
  }
}
@media screen and (min-width: $break-point-middle) {
.btnStyle_04{
  a{
    &::before{
      background-color: $key-color2;
      //background-color: rgba(255, 255, 255, 0.3);
    }
    .sec-label{
      font-size: 1.4rem;
      width: 48%;
      &::before{
        //background-color: lighten($key-color2,5%);
      }
      .sec-inner{
        text-align: left;
      }
      .sec-inner span{
        margin: 0;
        display: inline;
        font-size: 1.2rem;
      }
    }
    .sec-price{
      font-size: 1.2rem;
      @include transition(.3s);
      .sec-inner span{
        margin: 0 0 6px 0;
        //display: inline;
        font-size: 2.0rem;
        letter-spacing: .06em;
      }
    }
    &::before{
      content: '';
      position: absolute;
      left: -100%;
      top: -5px;
      width: 100%;
      height: 300px;
      @include transition(.3s);
    }
    .sec-inner{
      padding: 14px 18px;
    }
    i{
      display: block;
      position: absolute;
      line-height: 1em;
      top: -webkit-calc(50% - 7px);
      top: calc(50% - 7px);
      right: 7px;
      @include transition(.3s);
    }
  }
  a:hover{
    .sec-price{
      color: $white;
    }
    i{
      right: -106%;
    }
    &::before,.sec-label::before{
      left: 0;
    }
  }
  &.style_01{
    >.inner{
      display: block;
      >*{
        display: block;
      }
    }
    a .sec-label{
      width: auto;
    }
    a:hover{
      i{
        right: 0px;
      }
    }
  }
}
}
///////////////////////////////////////
.btnStyle_05{
  @extend .btnStyle_04;
  a{
    border: 1px solid $key-color;
    &::before{
      background-color: $key-color;
    }
    .sec-label{
      background-color: $key-color;
      &::before{
        background-color: $key-color;
      }
    }
    .sec-price{
      color: $key-color;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.btnStyle_05{
  a{
    .sec-inner{
      //padding: 14px 8px 14px 12px;
    }
    .sec-label{
      //width: auto;
    }
  }
}
}
///////////////////////////////////////
.btnStyle_06{
  @extend .btnStyle_04;
  a{
    .sec-price{
      .sec-inner{
        span.tx{
          @include fz_vw(10);
          line-height: 1em;
          font-weight: normal;
          margin-bottom: 0;
          br.pc{
            display: none!important;
          }
          >span{
            display: block;
          }
          >span.spInline{
            //opacity: 0;
            display: inline!important;
            font-size: 0px;
            @include fz_vw(10);
            font-weight: normal;
          }
        }
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.btnStyle_06{
  a{
    .sec-label{
      width: 63%;
    }
    .sec-price{
      font-size: 1.2rem;
      @include transition(.3s);
      .sec-inner{
        padding: 6px 5px;
        //@include setTbl;
        //padding: 0 0 0 24px;
        >span{
          //display: table-cell;
        }
        span.price{
          margin: 0;
          font-size: 1.8rem;
          letter-spacing: .06em;
        }
        span.tx{
          padding: 3px 0 0 0;
          font-size: .7rem;
          line-height: 1.5em;
          text-align: center;
          >span.spInline{
            font-size: .7rem;
          }
        }
      }
    }
  }
  a:hover{
    i{
      right: -10px;
    }
  }
}
}
@media screen and (min-width: 980px) {
// .btnStyle_06{
//   a{
//     .sec-price{
//       .sec-inner{
//         @include setTbl;
//         padding: 0 0 0 24px;
//         >span{
//           display: table-cell;
//         }
//       }
//     }
//   }
// }
}
///////////////////////////////////////
.btnStyle_07{
  @extend .btnStyle_06;
  a{
    &::before{
      background-color: $key-color;
    }
    .sec-label{
      background-color: $key-color;
      &::before{
        background-color: $key-color;
      }
    }
    .sec-price{
      border: 1px solid $key-color;
      border-left: none;
      color: $key-color;
    }
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.txStyle_01{
  @include fz_vw(30);
  line-height: 1em;
  position: relative;
  top: 0/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.txStyle_01{
  font-size: 4.0rem;
  //top: -15px;
}
}
///////////////////////////////////////
.txStyle_02{
  text-decoration: line-through;
}
///////////////////////////////////////
.txStyle_03{
  @include fz_vw(8);
  display: inline-block;
  line-height: 1.8em;
  margin-top: 16/375*100vw;
}
@media screen and (min-width: $break-point-middle) {
.txStyle_03{
  font-size: 1.0rem;
  margin-top: 16px;
}
}
///////////////////////////////////////
.txStyle_04{
  font-family: $fontEn;
  letter-spacing: .13em;
}
///////////////////////////////////////
.txStyle_05{
  color: #e1828c;
  font-weight: bold;
}
///////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
.txStyle_06{
  font-size: 1.0rem;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.linkStyle_01{
  color: $text-color;
  text-decoration: underline;
}
@media screen and (min-width: $break-point-middle) {
.linkStyle_01{
  &:hover{
    text-decoration: none;
    color: $hover-color;
  }
}
}
///////////////////////////////////
.linkStyle_02{
  color: $text-color;
  text-decoration: none;
}
@media screen and (min-width: $break-point-middle) {
.linkStyle_02{
  &:hover{
    text-decoration: underline;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.listStyle_01{
  @include fz_vw(13);
  padding-left: 1em;
  text-indent: -1em;
  margin-top: 6/375*100vw;
  li{
    margin-bottom: 4/375*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.listStyle_01{
  font-size: 1.3rem;
  padding-left: 1em;
  text-indent: -1em;
  margin-top: 0;
  li{
    margin-bottom: 0px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
