@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer{
  text-align: center;
  >.inner{
    padding: 10/375*100vw 0 35/375*100vw;
    .pages &{
      padding-top: 150/375*100vw;
    }
    .home &{
      position: relative;
      z-index: 3000;
      background-color: $key-color3;
    }
  }
  .logo{
    margin-bottom: 26/375*100vw;
    a{
      svg{
        display: inline-block;
        fill:$text-color;
        width: 92/375*100vw;
        height: 46/375*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
footer{
  >.inner{
    padding: 10px 0 45px;
    .pages &{
      padding-top: 200px;
    }
  }
  .logo{
    margin-bottom: 30px;
    a{
      svg{
        width: 92px;
        height: 46px;
      }
      &:hover{
        @include opacity(.8);
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.footNavMain{
  margin-bottom: 28/375*100vw;
  ul{
    @include killSpace;
    text-align: center;
  }
  a{
    overflow: hidden;
    display: inline-block;
    @include over_01($hover-color);
    svg{
      display: inline-block;
      fill:$text-color;
      width: 46/375*100vw;
      height: 10/375*100vw;
    }
    &.current{
      cursor: default;
      pointer-events:none;
      &::before{
        width: 100%;
      }
    }
  }
  li{
    margin-right: 20/375*100vw;
    &:last-child{
      margin-right: 0;
    }
  }
  li:nth-child(2) a svg{
    width: 32/375*100vw;
  }
  li:nth-child(4) a svg{
    width: 94/375*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.footNavMain{
  margin-bottom: 30px;
  a{
    svg{
      width: 48px;
      height: 10px;
    }
  }
  a:hover{
    svg{
      fill:$hover-color;
    }
  }
  li{
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
  }
  li:nth-child(2) a svg{
    width: 32px;
  }
  li:nth-child(3) a svg{
    width: 50px;
  }
  li:nth-child(4) a svg{
    width: 94px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.footContact{
  margin-bottom: 28/375*100vw;
  h2{
    line-height: 1em;
    @include fz_vw(12);
    font-weight: normal;
    letter-spacing: .04em;
    margin-bottom: 8/375*100vw;
  }
  a{
    display: inline-block;;
    overflow: hidden;
    @include over_01($hover-color);
    svg{
      fill:$text-color;
      width: 170/375*100vw;
      height: 19/375*100vw;
    }
  }
  .add{
    @include fz_vw(12);
    position: relative;
    top: -7px;
  }
}
@media screen and (min-width: $break-point-middle) {
.footContact{
  margin-bottom: 30px;
  h2{
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  a{
    svg{
      width: 142px;
      height: 14px;
    }
  }
  a:hover{
    svg{
      fill:$hover-color;
    }
  }
  .add{
    font-size: 1.1rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.footNavSub{
  @include fz_vw(12);
  line-height: 1em;
  li{
    margin-bottom: 11/375*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
  a{
    color: $text-color;
    overflow: hidden;
    display: inline-block;
    @include over_01($hover-color);
  }
}
@media screen and (min-width: $break-point-middle) {
.footNavSub{
  font-size: 1.2rem;
  ul{
    @include killSpace;
  }
  li{
    margin-bottom: 0;
    padding-right: 16px;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      display: inline-block;
      @include border-radius(100%);
      background-color: $text-color;
      right: 7px;
      -webkit-top: calc(50% - 2px);
      top: calc(50% - 2px);
    }
    &:last-child::after{
      display: none;
    }
  }
  a:hover{
    color: $hover-color;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer .copyRights{
  margin-top: 80/375*100vw;
  @include fz_vw(10);
  line-height: 1em;
  font-size: 1em;
  font-family: $fontEn;
  letter-spacing: .08em;
  svg{
    display: inline-block;
    fill:$text-color;
    width: 188/375*100vw;
    height: 10/375*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
footer .copyRights{
  margin-top: 85px;
  font-size: .8rem;
  svg{
    width: 188px;
    height: 10px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
