@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
body{
  &.breakH{
    height: auto!important;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrapper{
  position: relative;
  overflow: hidden;
  height: 0;
  @include opacity(0);
  &.loaded{
    height: auto;
    @include opacity(1);
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#escapeFix{
  background-color: $key-color3;
  position: relative;
}
@media screen and (min-width: $break-point-middle) {
#escapeFix{
  background-color:transparent;
  position: static;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVis{
  height: 90vh;
  background-color: $key-bg;
  position: relative;
  z-index: 2000;
  >.inner{
    @include setTbl;
    height: 100%;
  }
  .photos{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    >*{
      height: 45vh;
      position: relative;
    }
  }
  .photo{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    .mask{
			@include transition(1s);
			overflow:hidden;
			width:0%;
			height:100%;
      position: relative;
			&.slide{
				width:100%;
			}
		}
    span{
      display: block;
      position: absolute;
      top: 0;
      left: 0vw;
      width: 100vw;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      @include scale(1);
      @include background-size(100% auto);
    }
  }
  .mask.slide span{
    animation-name:sizeScale;
    animation-duration:20s;
    animation-direction:alternate;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
    @include transform-origin(50%,50%);
  }
  .logo{
    position: relative;
    @include setTbl;
    height: 100%;
    h1{
      text-align: center;
      svg{
        display: inline-block;
        fill:$white;
        width: 120/375*100vw;
        height: 60/375*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
#mainVis{
  height: 100vh;
  margin-bottom: 10px;
  .photos{
    @include setTbl;
    >*{
      height: 100%;
    }
  }
  .photo{
    span{
      width: 50vw;
      //left: -30vw;
      height: 100vh;
      //@include background-size(auto 100%);
      @include background-size(cover);
    }
  }
  .logo{
    h1{
      svg{
        width: 160px;
        height: 80px;
      }
    }
  }
}
}
/////////////////////////////
@keyframes sizeScale {
	0%{
		@include scale(1);
	}
    100% {
		@include scale(1.3);
    }
}
////////////////////////////////////////////////
////////////////////////////////////////////////
$step:54;
.logoAnime{
  color: rgba(255, 255, 255, 0);
  display: inline-block;
	background: url('../img/sprite.png') no-repeat;
	width: 362px;
	height: 180px;
  @inlcude background-size(cover);
	animation:logoMotion 1.8s steps($step) forwards;
  @include scale(120/362);
}
@media screen and (min-width: $break-point-middle) {
.logoAnime{
  @include scale(180/362);
}
}
@keyframes logoMotion {
  to {
    background-position: -362*$step+px 0;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.itemSec{
  .itemHead{
    display: flex;
    flex-direction: column-reverse;
    .col-img{
      margin-bottom: 40/375*100vw;
    }
  }
  .eyeCatch{
    position: relative;
    margin-left: -30/375*100vw;
    margin-right: -30/375*100vw;
  }
  .imgStyle{
    img{
      @include imgset;
    }
    &.style_01{
      position: relative;
      &::after{
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: $key-color3;
        mix-blend-mode: multiply;
      }
    }
  }
  .content{
    position: relative;
    //margin-top: 30/375*100vw;
  }
  h2{
    margin-bottom: 9/375*100vw;
    svg{
      display: inline-block;
      fill:$text-color;
      width: 154/375*100vw;
      height: 18/375*100vw;
      .product-mist &{
        width: 125/375*100vw;
      }
    }
  }
  .price{
    line-height: 1em;
    @include fz_vw(12);
    margin-bottom: 16/375*100vw;
    span{
      font-family: $fontEn;
      @include fz_vw(14);
      font-weight: bold;
      letter-spacing: .03em;
      padding-right: 4/375*100vw;
    }
  }
  .btnWrap{
    >*{
      display: block;
    }
  }
  .info{
    margin-top: 25/375*100vw;
    >*{
      position: relative;
      &:first-child{
        margin-bottom: (20/12)+em;
        @include display-flex;
      	@include flex-direction(column-reverse);
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .summary{
    line-height: (20/12)+em;
    @include fz_vw(12);
    letter-spacing: -.01em;
    text-align: justify;
    >*{
      margin-bottom: (20/12)+em;
      &:last-child{
        margin-bottom: 0;
      }
    }
    dt{
      font-weight: bold;
    }
    dl{
      span{
        padding-left: 1em;
        text-indent: -1em;
        display: block;
        margin-bottom: 3/375*100vw;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    dl.style_01{
      >*{
        display:inline-block;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.itemSec{
  .itemHead{
    @include setTbl;
    .col-content{
      &.style_01{
        padding-top: 80px;
      }
    }
  }
  .eyeCatch{
    margin-left: 0;
    margin-right: 0;
  }
  .imgStyle{
    max-width: 670px;
    margin: 0 auto;
  }
  .content{
    margin-top: -46px;
  }
  h2{
    margin-bottom: 16px;
    svg{
      width: 192px;
      height: 23px;
      .product-mist &{
        width: 156px;
      }
      .product-cream &{
        width: 184px;
      }
    }
  }
  .price{
    font-size: 1.2rem;
    margin-bottom: 27px;
    span{
      font-size: 1.4rem;
      padding-right: 4px;
    }
  }
  .btnWrap{
    text-align: left;
    >*{
      display: inline-block;
      min-width: 276px;
    }
  }
  .info{
    margin-top: 50px;
    @include setTbl;
    >*{
      vertical-align: top;
      &:first-child{
        margin-bottom: 0;
        padding-right: 16/980*100%;
        display: table-cell;
        >*{
          display: block;
        }
      }
      &:last-child{
        padding-left: 16/980*100%;
      }
    }
  }
  .summary{
    font-size: 1.2rem;
    letter-spacing: -.05em;;
  }
  dl{
    span{
      margin-bottom: 3px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.movieWrap{
  position: relative;
  margin-left: -30/375*100vw;
  margin-right: -30/375*100vw;
  .home &{
    margin-left: 0;
    margin-right: 0;
  }
  .screen{
    video{
      width: 100%;
    }
  }
  .ctr{
    width: 100%;
    height: 100%;
    padding-top:56.25%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    @include background-size(cover);
    @include transition(.3s);
    &.hide{
      @include opacity(0);
      z-index: -1;
    }
  }
  .btnCont{
    width: 100%;
    height: 100%;
    @include setTbl;
    position: absolute;
    top: 0;
    left: 0;
    >.inner{
      text-align: center;
      position: relative;
    }
    span{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include display-flex;
      align-items: center;
      &.over{
        @include opacity(0);
        &::before{
          content: '';
          width: 58/375*100vw;
          height: 58/375*100vw;
          @include border-radius(100%);
          position: absolute;
          top: -webkit-calc(50% - 29/375*100vw);
          left: calc(50% - 29/375*100vw);
          top: -webkit-calc(50% - 29/375*100vw);
          left: calc(50% - 29/375*100vw);
          background-color: $white;
        }
      }
    }
    i{
      position: relative;
      margin: auto;
      color: $white;
      line-height: 1em;
      @include fz_vw(58);
      @include transition(.3s);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.movieWrap{
  margin-left: 0;
  margin-right: 0;
  .btnCont{
    span{
      &.over{
        &::before{
          width: 70px;
          height: 70px;
          top: -webkit-calc(50% - 35px);
          left: calc(50% - 35px);
          top: -webkit-calc(50% - 35px);
          left: calc(50% - 35px);
        }
      }
    }
    i{
      font-size: 15.0rem;
    }
  }
  .ctr:hover .btnCont{
    @include opacity(.7);
  }
  .ctr:hover .btnCont i{
    color: $key-color;
  }
  .ctr:hover .btnCont span.over{
    @include opacity(1);
  }
  .ctr:hover .btnCont span.normal{
    @include opacity(0);
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.videoSummary{
  background-color: rgba(127,159,199,.9);
  color: $white;
  line-height: (24/12)+em;
  @include fz_vw(12);
  @include transition(.8s);
  position: relative;
  margin-top: -30/375*100vw;
  letter-spacing: -.05em;
  >.inner{
    padding: 40/375*100vw 20/375*100vw;
  }
  &.hide{
    z-index: -1;
    @include opacity(0);
  }
}
@media screen and (min-width: $break-point-middle) {
.videoSummary{
  line-height: (34/14)+em;
  font-size: 1.4rem;
  position: absolute;
  left: 0;
  bottom: -70px;
  margin-top: 0;
  letter-spacing: 0;
  >.inner{
    padding: 84px 68px;
  }
}
}
@media screen and (min-width: 1600px) {
.videoSummary{
  left: 200/$design-num*100vw;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ctaSec{
  margin-bottom: 36/375*100vw;
  >*{
    margin-bottom: 18/375*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .col-sub{
    >*{
      margin-bottom: 18/375*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.ctaSec{
  //margin-bottom: 32px;
  //margin-top: 16px;
  margin-bottom: 0;
  //position: absolute;
  //bottom: 0;
  padding-right: 16/980*100%;
  >*{
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .col-sub{
    @include display-flex;
  	@include flex-wrap(wrap);
  	@include justify-content(space-between);
    a>.inner{
      display: block;
      >*{
        display: block;
      }
    }
    >*{
      margin: 0;
      width: 228/474*100%;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ctaAddTx{
  margin-top: 10/375*100vw;
  p{
    @include fz_vw(12);
  }
}
@media screen and (min-width: $break-point-middle) {
.ctaAddTx{
  margin-top: 6px;
  p{
    font-size: 1.3rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.basicSet{
  position: relative;
  //background-color: rgba(255, 255, 255, .3);
  padding: 0 0 70/375*100vw;
  >.inner{
    padding: 314/375*100vw 30/375*100vw 0;
    position: relative;
  }
  .col-img{
    position: absolute;
    top: -74/375*100vw;
    left: 0;
    width: 100vw;
  }
  h2{
    line-height: 1em;
    @include fz_vw(24);
    letter-spacing: .15em;
    font-family: $fontEn;
    margin-bottom: 15/375*100vw;
    font-weight: normal;
  }
  .summary{
    @include fz_vw(13);
    line-height: 1.6em;
    margin-bottom: 28/375*100vw;
  }
  .btnCol{
    >*{
      //margin-bottom: 20/375*100vw;
      &:last-child{
        margin-bottom: 0;
      }
      &.style_01{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.basicSet{
  padding: 80px 40px;
  >.inner{
    padding: 0;
    max-width: 980px;
    margin: 0 auto;
  }
  .col-content{
    width: 474/980*100%;
  }
  .col-img{
    top: auto;
    left: auto;
    right: 0;
    bottom: -30px;
    width: 472/980*100%;
  }
  h2{
    font-size: 3.0rem;
    margin-bottom: 22px;
  }
  .summary{
    font-size: 1.4rem;
    margin-bottom: 30px;
  }
  .btnCol{
    >*{
      //margin-bottom: 28px;
      &:last-child{
        margin-bottom: 0;
      }
      &.style_01{
        margin-bottom: 0;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.product-serum{
  .onlyMist{
    display: none;
  }
  .onlyCream{
    display: none;
  }
  .hideSerum{
    display: none;
  }
}
.product-mist{
  .onlySerum{
    display: none;
  }
  .onlyCream{
    display: none;
  }
  .hideMist{
    display: none;
  }
}
.product-cream{
  .onlyMist{
    display: none;
  }
  .onlySerum{
    display: none;
  }
  .hideCream{
    display: none;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.addToggle{
  dt{
    cursor: pointer;
    position: relative;
    transition: .3s;
    span.icon{
      display: inline-block;
      text-indent: 0;
      padding-left: 3px;
      margin: 0;
      position: relative;
      top: -1px;
      line-height: 1em;
      @include fz_vw(10);
      &.active{
        display: none;
      }
    }
    &.active{
      span.icon{
        &.normal{
          display: none;
        }
        &.active{
          display: inline-block;
        }
      }
    }
  }
  dd{
    display: none;
  }
}
@media screen and (min-width: $break-point-middle) {
.addToggle{
  dt{
    span.icon{
      font-size: 1.0rem;
    }
  }
  dt:hover{
    opacity: .8;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.insentiveSec{
  //background-color: rgba(255, 255, 255, .3);
  margin-left: -30/$design-num-sp*100vw;
  margin-right: -30/$design-num-sp*100vw;
  >.inner{
    padding: 40/$design-num-sp*100vw 30/$design-num-sp*100vw;
    position: relative;
  }
  .col-img{
    .imgStyle{
      width: 257/$design-num-sp*100vw;
      margin: 30/$design-num-sp*100vw 10/$design-num-sp*100vw 0 auto;
      img{
        @include imgset;
      }
    }
  }
  h2{
    line-height: (27/14)+em;
    @include fz_vw(14);
    margin-bottom: 10/$design-num-sp*100vw;
  }
  .summary{
    line-height: (20/12)+em;
    @include fz_vw(12);
  }
  .caution{
    text-indent: -1em;
    padding-left: 1em;
    line-height: (15/10)+em;
    @include fz_vw(10);
    margin-top: 5/$design-num-sp*100vw;
    letter-spacing: -.06em;
  }
}
@media screen and (min-width: $break-point-middle) {
.insentiveSec{
  margin-left: 0;
  margin-right: 0;
  >.inner{
    //padding: 58px 95/980*100%;
    padding: 58px 0;
  }
  .col-img{
    position: absolute;
    right: 0;
    bottom: -40px;
    .imgStyle{
      width: auto;
      margin: 0;
    }
  }
  h2{
    line-height: (30/22)+em;
    font-size: 2.2rem;
    margin-bottom: 6px;
  }
  .summary{
    line-height: (23/12)+em;
    font-size: 1.2rem;
  }
  .caution{
    line-height: (18/10)+em;
    font-size: 1.0rem;
    margin-top: 0;
    letter-spacing: 0;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.returnSec{
  background-color: rgba(255, 255, 255, .3);
  padding: 60/$design-num-sp*100vw 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.returnSec{
  padding: 70px 40px;
  >.inner{
    max-width: 980px;
    margin: 0 auto;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.topMovie{
  position: relative;
  z-index: 2000;
  margin-bottom: 10/375*100vw;
  margin-top: 10/375*100vw;
  video{
    width: 100%;
    padding: 0;
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.topMovie{
  margin-bottom: 10px;
  margin-top: 0;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
